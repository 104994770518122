import logo from "./logo.svg";
import "./App.css";
import Bmu6 from "./components/Bmu6";
import MainLayout from "./components/MainLayout";
import Layout from "./components/Layout";
import { Routes, Route } from "react-router-dom";
import GenByFuel from "./components/GenByFuel";
import GenByFuel2 from "./components/GenByFuel7";
import DemandOuturn from "./components/DemandOuturn";
import Disbsad from "./components/Disbsad";
import Sbpsp from "./components/Sbpsp2";
import TotalBidOffer from "./components/TotalBidOffer4";
import WindForecast from "./components/WindForecast";
import DerateMargin from "./components/DerateMargin";
import Oc2Margin2_156 from "./components/Oc2Margin2_156";
import Oc2Margin2_14 from "./components/Oc2Margin2_14";
import Uou2t14d from "./components/Uou2t14d";
import Uou2t3yw from "./components/Uou2t3yw";
import ActualMetered from "./components/ActualMetered";
import MarketIndexData from "./components/MarketIndexData";
import Soso from "./components/Soso";
import Warnings from "./components/Warnings";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route path="/" element={<MainLayout />}>
          <Route path="bmu">
            <Route path=":id" element={<Bmu6 />} />
          </Route>
          <Route path="uou2t14d">
            <Route path=":id" element={<Uou2t14d />} />
          </Route>
          <Route path="uou2t3yw">
            <Route path=":id" element={<Uou2t3yw />} />
          </Route>
          <Route path="metered">
            <Route path=":id" element={<ActualMetered />} />
          </Route>
          <Route path="fuel">
            <Route index element={<GenByFuel />} />
          </Route>
          <Route path="fuel2">
            <Route index element={<GenByFuel2 />} />
          </Route>
          <Route path="system">
            <Route index element={<DemandOuturn />} />
          </Route>
          <Route path="system2">
            <Route index element={<TotalBidOffer />} />
          </Route>
          <Route path="sbpssp">
            <Route index element={<Sbpsp />} />
          </Route>
          <Route path="disbsad">
            <Route index element={<Disbsad />} />
          </Route>
          <Route path="wind">
            <Route index element={<WindForecast />} />
          </Route>
          <Route path="derate">
            <Route index element={<DerateMargin />} />
          </Route>
          <Route path="ocfmd2">
            <Route index element={<Oc2Margin2_14 />} />
          </Route>
          <Route path="ocfm2156">
            <Route index element={<Oc2Margin2_156 />} />
          </Route>
          <Route path="mid">
            <Route index element={<MarketIndexData />} />
          </Route>
          <Route path="soso">
            <Route index element={<Soso />} />
          </Route>
          <Route path="warning">
            <Route index element={<Warnings />} />
          </Route>
        </Route>
      </Route>
    </Routes>
  );
}

export default App;
