import React from "react";
import { useState, useEffect, useContext } from "react";
import { Chart } from "react-google-charts";
import { useParams } from "react-router-dom";
import MainContext from "../context/MainContext";
import { addDays, format, getDate, getMonth } from "date-fns";
import { ClipLoader } from "react-spinners";
import { getTimezoneOffset } from "date-fns-tz";

const DemandOuturn = () => {
  const { datePicker, loading, setLoading } = useContext(MainContext);
  const [data, setData] = useState([]);
  const [options, setOptions] = useState([]);

  let chartData = [];

  useEffect(() => {
    setLoading(true);
    const startDate = format(new Date(datePicker), "yyyy-MM-dd");
    const endDate = format(new Date(addDays(startDate, 1)), "yyyy-MM-dd");
    let tzDiff = getTimezoneOffset("Europe/Dublin", startDate) / 1000 / 60 / 60;
    let newStartDate;
    let newEndDate;
    if (tzDiff === 1) {
      newStartDate =
        format(new Date(addDays(startDate, -1)), "yyyy-MM-dd") + "T23%3A30z";
      newEndDate = endDate + "T22%3A30z";
    } else {
      newStartDate = startDate + "T00%3A30z";
      newEndDate = endDate + "T23%3A30z";
    }

    const getData = async () => {
      //console.log(e);
      const response = await fetch(
        `https://data.elexon.co.uk/bmrs/api/v1/forecast/indicated/day-ahead/evolution?settlementDate=${startDate}&settlementPeriod=1&settlementPeriod=2&settlementPeriod=3&settlementPeriod=4&settlementPeriod=5&settlementPeriod=6&settlementPeriod=7&settlementPeriod=8&settlementPeriod=9&settlementPeriod=10&settlementPeriod=11&settlementPeriod=12&settlementPeriod=13&settlementPeriod=14&settlementPeriod=15&settlementPeriod=16&settlementPeriod=17&settlementPeriod=18&settlementPeriod=19&settlementPeriod=20&settlementPeriod=21&settlementPeriod=22&settlementPeriod=23&settlementPeriod=24&settlementPeriod=25&settlementPeriod=26&settlementPeriod=27&settlementPeriod=28&settlementPeriod=29&settlementPeriod=30&settlementPeriod=31&settlementPeriod=32&settlementPeriod=33&settlementPeriod=34&settlementPeriod=35&settlementPeriod=36&settlementPeriod=37&settlementPeriod=38&settlementPeriod=39&settlementPeriod=40&settlementPeriod=41&settlementPeriod=42&settlementPeriod=43&settlementPeriod=44&settlementPeriod=45&settlementPeriod=46&settlementPeriod=47&settlementPeriod=48&settlementPeriod=49&settlementPeriod=50&boundary=N&format=json`
        // `https://data.elexon.co.uk/bmrs/api/v1/forecast/indicated/day-ahead/evolution?settlementDate=2024-09-18&settlementPeriod=4&settlementPeriod=5&settlementPeriod=6&boundary=N&format=json`
      );
      let data1 = await response.json();
      console.log(data1);

      const response2 = await fetch(
        `https://data.elexon.co.uk/bmrs/api/v1/demand/outturn?settlementDateFrom=${startDate}&settlementDateTo=${startDate}&settlementPeriod=1&settlementPeriod=2&settlementPeriod=3&settlementPeriod=4&settlementPeriod=5&settlementPeriod=6&settlementPeriod=7&settlementPeriod=8&settlementPeriod=9&settlementPeriod=10&settlementPeriod=11&settlementPeriod=12&settlementPeriod=13&settlementPeriod=14&settlementPeriod=15&settlementPeriod=16&settlementPeriod=17&settlementPeriod=18&settlementPeriod=19&settlementPeriod=20&settlementPeriod=21&settlementPeriod=22&settlementPeriod=23&settlementPeriod=24&settlementPeriod=25&settlementPeriod=26&settlementPeriod=27&settlementPeriod=28&settlementPeriod=29&settlementPeriod=30&settlementPeriod=31&settlementPeriod=32&settlementPeriod=33&settlementPeriod=34&settlementPeriod=35&settlementPeriod=36&settlementPeriod=37&settlementPeriod=38&settlementPeriod=39&settlementPeriod=40&settlementPeriod=41&settlementPeriod=42&settlementPeriod=43&settlementPeriod=44&settlementPeriod=45&settlementPeriod=46&settlementPeriod=47&settlementPeriod=48&settlementPeriod=49&settlementPeriod=50&boundary=N&format=json`
      );
      let data2 = await response2.json();
      console.log(data2);
      const response3 = await fetch(
        `https://data.elexon.co.uk/bmrs/api/v1/forecast/demand/day-ahead/evolution?settlementDate=${startDate}&settlementPeriod=1&settlementPeriod=2&settlementPeriod=3&settlementPeriod=4&settlementPeriod=5&settlementPeriod=6&settlementPeriod=7&settlementPeriod=8&settlementPeriod=9&settlementPeriod=10&settlementPeriod=11&settlementPeriod=12&settlementPeriod=13&settlementPeriod=14&settlementPeriod=15&settlementPeriod=16&settlementPeriod=17&settlementPeriod=18&settlementPeriod=19&settlementPeriod=20&settlementPeriod=21&settlementPeriod=22&settlementPeriod=23&settlementPeriod=24&settlementPeriod=25&settlementPeriod=26&settlementPeriod=27&settlementPeriod=28&settlementPeriod=29&settlementPeriod=30&settlementPeriod=31&settlementPeriod=32&settlementPeriod=33&settlementPeriod=34&settlementPeriod=35&settlementPeriod=36&settlementPeriod=37&settlementPeriod=38&settlementPeriod=39&settlementPeriod=40&settlementPeriod=41&settlementPeriod=42&settlementPeriod=43&settlementPeriod=44&settlementPeriod=45&settlementPeriod=46&settlementPeriod=47&settlementPeriod=48&settlementPeriod=49&settlementPeriod=50&boundary=N&format=json`
      );
      let data3 = await response3.json();
      console.log(data3);

      const response4 = await fetch(
        `https://data.elexon.co.uk/bmrs/api/v1/balancing/settlement/system-prices/${startDate}?format=json`
      );
      let data4 = await response4.json();
      console.log(data4);
      const response5 = await fetch(
        `https://data.elexon.co.uk/bmrs/api/v1/balancing/nonbm/disbsad/summary?from=${newStartDate}&to=${newEndDate}&format=json`
      );
      let data5 = await response5.json();
      console.log(data5);
      chartData = [
        [
          { type: "string", label: "Period" },
          { type: "number", label: "Demand" },
          { type: "number", label: "Imbalance" },
          { type: "number", label: "Outturn" },
          { type: "number", label: "NIV" },
          { type: "number", label: "BidOffers" },
          { type: "number", label: "BISBSAD" },
        ],
      ];
      let temp = [];
      for (let a = 0; a < 50; a++) {
        temp = [];
        temp.push(a + 1);
        let filtered = data1.data
          .filter((x) => x.settlementPeriod === a + 1)
          .sort((a, b) => new Date(b.publishTime) - new Date(a.publishTime));
        let filtered2 = data2.data
          .filter((x) => x.settlementPeriod === a + 1)
          .sort((a, b) => new Date(b.publishTime) - new Date(a.publishTime));
        let filtered3 = data3.data
          .filter((x) => x.settlementPeriod === a + 1)
          .sort((a, b) => new Date(b.publishTime) - new Date(a.publishTime));
        let filtered4 = data4.data.filter((x) => x.settlementPeriod === a + 1);
        let filtered5 = data5.data.filter((x) => x.settlementPeriod === a + 1);

        if (filtered3.length > 0) {
          if (filtered3[0].nationalDemand !== null) {
            temp.push(filtered3[0].nationalDemand);
          } else {
            temp.push(filtered3[1].nationalDemand);
          }
        } else {
          temp.push(null);
        }
        if (a < 46) {
          temp.push(filtered[1].indicatedImbalance);
        } else {
          temp.push(null);
        }
        if (filtered2.length > 0) {
          temp.push(filtered2[0].initialDemandOutturn);
        } else {
          temp.push(null);
        }
        if (filtered4.length > 0) {
          temp.push(filtered4[0].netImbalanceVolume);
        } else {
          temp.push(null);
        }
        if (filtered4.length > 0) {
          temp.push(
            filtered4[0].totalAcceptedOfferVolume +
              filtered4[0].totalAcceptedBidVolume
          );
        } else {
          temp.push(null);
        }
        if (filtered5.length > 0) {
          temp.push(filtered5[0].netVolume);
        } else {
          temp.push(null);
        }
        chartData.push(temp);
      }
      //   chartData = [
      //     ["Year", "Sales", "Expenses"],
      //     ["2004", 1000, 400],
      //     ["2005", 1170, 460],
      //     ["2006", 660, 1120],
      //     ["2007", 1030, 540],
      //   ];
      setData(chartData);
      console.log(chartData);
      setOptions({
        title: "Demand Outturn (Note: NIV positive, period was short)",

        height: 800,
        width: 1748,
        series: {
          0: { lineWidth: 2, pointSize: 2.1 },
          1: {
            lineWidth: 2,
            pointSize: 2.1,
            targetAxisIndex: 1,
            type: "lines",
          },
          2: { lineWidth: 2, pointSize: 2.1 },
          3: {
            targetAxisIndex: 1,
            type: "bars",
          },
          4: {
            targetAxisIndex: 1,
            type: "bars",
          },
          5: {
            targetAxisIndex: 1,
            type: "bars",
          },
        },
        hAxis: {
          textStyle: { fontSize: 10 },
          title: "Period",
          //format: 'HH:mm',
          //minValue: new Date(y,m,d,0,0,0),
          // maxValue: new Date(y,m,d+1,0,0,0),
          minValue: 0,
          maxValue: 50,
          viewWindow: {
            //min: new Date(y,m,d,0,0,0),
            //max: new Date(y,m,d+1,0,0,0)
            //min: 1,
            max: 50,
          },
        },
        vAxes: {
          0: { title: "Forecast Demand, Demand Outturn" },
          1: { title: "Gen Demand Imbal, NIV, Net Bid Offers" },
        },
        chartArea: { backgroundColor: "#fffff0" },
      });
      setLoading(false);
    };
    getData();
  }, [datePicker]);

  return (
    <>
      {loading ? (
        <div style={{ textAlign: "center" }}>
          <ClipLoader loading={loading} />{" "}
        </div>
      ) : (
        <div
          style={{
            border: "1px solid #DCDCDC",
            height: "810px",
            width: "1750px",
            float: "none",
          }}
        >
          <Chart chartType="LineChart" options={options} data={data} />
        </div>
      )}
    </>
  );
};

export default DemandOuturn;
