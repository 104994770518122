import React from "react";
import { useState, useEffect, useContext } from "react";
import { Chart } from "react-google-charts";
import { useParams } from "react-router-dom";
import MainContext from "../context/MainContext";
import { addDays, format } from "date-fns";
import { ClipLoader } from "react-spinners";

const TotalBidOffer4 = () => {
  const { datePicker, loading, setLoading } = useContext(MainContext);
  const [chartData, setChartData] = useState();
  const [chartData2, setChartData2] = useState();

  const startDate = format(new Date(datePicker), "yyyy-MM-dd");

  useEffect(() => {
    const getData = async () => {
      let outturn = {};
      let bidOfferVol = {
        bid: {
          p1: 0,
          p2: 0,
          p3: 0,
          p4: 0,
          p5: 0,
          p6: 0,
          p7: 0,
          p8: 0,
          p9: 0,
          p10: 0,
          p11: 0,
          p12: 0,
          p13: 0,
          p14: 0,
          p15: 0,
          p16: 0,
          p17: 0,
          p18: 0,
          p19: 0,
          p20: 0,
          p21: 0,
          p22: 0,
          p23: 0,
          p24: 0,
          p25: 0,
          p26: 0,
          p27: 0,
          p28: 0,
          p29: 0,
          p30: 0,
          p31: 0,
          p32: 0,
          p33: 0,
          p34: 0,
          p35: 0,
          p36: 0,
          p37: 0,
          p38: 0,
          p39: 0,
          p40: 0,
          p41: 0,
          p42: 0,
          p43: 0,
          p44: 0,
          p45: 0,
          p46: 0,
          p47: 0,
          p48: 0,
          p49: 0,
          p50: 0,
        },
        offer: {
          p1: 0,
          p2: 0,
          p3: 0,
          p4: 0,
          p5: 0,
          p6: 0,
          p7: 0,
          p8: 0,
          p9: 0,
          p10: 0,
          p11: 0,
          p12: 0,
          p13: 0,
          p14: 0,
          p15: 0,
          p16: 0,
          p17: 0,
          p18: 0,
          p19: 0,
          p20: 0,
          p21: 0,
          p22: 0,
          p23: 0,
          p24: 0,
          p25: 0,
          p26: 0,
          p27: 0,
          p28: 0,
          p29: 0,
          p30: 0,
          p31: 0,
          p32: 0,
          p33: 0,
          p34: 0,
          p35: 0,
          p36: 0,
          p37: 0,
          p38: 0,
          p39: 0,
          p40: 0,
          p41: 0,
          p42: 0,
          p43: 0,
          p44: 0,
          p45: 0,
          p46: 0,
          p47: 0,
          p48: 0,
          p49: 0,
          p50: 0,
        },
      };

      let bidOfferCash = {
        bid: {
          p1: 0,
          p2: 0,
          p3: 0,
          p4: 0,
          p5: 0,
          p6: 0,
          p7: 0,
          p8: 0,
          p9: 0,
          p10: 0,
          p11: 0,
          p12: 0,
          p13: 0,
          p14: 0,
          p15: 0,
          p16: 0,
          p17: 0,
          p18: 0,
          p19: 0,
          p20: 0,
          p21: 0,
          p22: 0,
          p23: 0,
          p24: 0,
          p25: 0,
          p26: 0,
          p27: 0,
          p28: 0,
          p29: 0,
          p30: 0,
          p31: 0,
          p32: 0,
          p33: 0,
          p34: 0,
          p35: 0,
          p36: 0,
          p37: 0,
          p38: 0,
          p39: 0,
          p40: 0,
          p41: 0,
          p42: 0,
          p43: 0,
          p44: 0,
          p45: 0,
          p46: 0,
          p47: 0,
          p48: 0,
          p49: 0,
          p50: 0,
        },
        offer: {
          p1: 0,
          p2: 0,
          p3: 0,
          p4: 0,
          p5: 0,
          p6: 0,
          p7: 0,
          p8: 0,
          p9: 0,
          p10: 0,
          p11: 0,
          p12: 0,
          p13: 0,
          p14: 0,
          p15: 0,
          p16: 0,
          p17: 0,
          p18: 0,
          p19: 0,
          p20: 0,
          p21: 0,
          p22: 0,
          p23: 0,
          p24: 0,
          p25: 0,
          p26: 0,
          p27: 0,
          p28: 0,
          p29: 0,
          p30: 0,
          p31: 0,
          p32: 0,
          p33: 0,
          p34: 0,
          p35: 0,
          p36: 0,
          p37: 0,
          p38: 0,
          p39: 0,
          p40: 0,
          p41: 0,
          p42: 0,
          p43: 0,
          p44: 0,
          p45: 0,
          p46: 0,
          p47: 0,
          p48: 0,
          p49: 0,
          p50: 0,
        },
      };
      let disbsadCash = {
        p1: 0,
        p2: 0,
        p3: 0,
        p4: 0,
        p5: 0,
        p6: 0,
        p7: 0,
        p8: 0,
        p9: 0,
        p10: 0,
        p11: 0,
        p12: 0,
        p13: 0,
        p14: 0,
        p15: 0,
        p16: 0,
        p17: 0,
        p18: 0,
        p19: 0,
        p20: 0,
        p21: 0,
        p22: 0,
        p23: 0,
        p24: 0,
        p25: 0,
        p26: 0,
        p27: 0,
        p28: 0,
        p29: 0,
        p30: 0,
        p31: 0,
        p32: 0,
        p33: 0,
        p34: 0,
        p35: 0,
        p36: 0,
        p37: 0,
        p38: 0,
        p39: 0,
        p40: 0,
        p41: 0,
        p42: 0,
        p43: 0,
        p44: 0,
        p45: 0,
        p46: 0,
        p47: 0,
        p48: 0,
        p49: 0,
        p50: 0,
      };
      setLoading(true);

      let bidCallString = "";

      const responseTotalBmuBids = await fetch(
        `https://data.elexon.co.uk/bmrs/api/v1/balancing/settlement/acceptance/volumes/all/bid/${startDate}?${bidCallString}format=json`
      );
      const unfilterBidVol = await responseTotalBmuBids.json();
      const bidVol = unfilterBidVol.data;
      //console.log(bidVol);
      let sp;
      for (let c = 0; c < bidVol.length; c++) {
        sp = `p${bidVol[c].settlementPeriod}`;
        bidOfferVol.bid[sp] =
          bidOfferVol.bid[sp] + bidVol[c].totalVolumeAccepted;
      }

      bidCallString = "";
      const bidCashResponse = await fetch(
        `https://data.elexon.co.uk/bmrs/api/v1/balancing/settlement/indicative/cashflows/all/bid/${startDate}?${bidCallString}format=json`
      );
      const unfilterBidCash = await bidCashResponse.json();
      const bidCash = unfilterBidCash.data;

      for (let c = 0; c < bidCash.length; c++) {
        sp = `p${bidCash[c].settlementPeriod}`;
        bidOfferCash.bid[sp] = bidOfferCash.bid[sp] + bidCash[c].totalCashflow;
      }

      let offerCallString = "";

      offerCallString = "";
      const offerVolResponse = await fetch(
        `https://data.elexon.co.uk/bmrs/api/v1/balancing/settlement/acceptance/volumes/all/offer/${startDate}?${offerCallString}format=json`
      );
      const unfilterOfferVol = await offerVolResponse.json();
      const offerVol = unfilterOfferVol.data;
      //console.log(offerVol);
      //console.log(BidCallString);
      //let sp;
      for (let c = 0; c < offerVol.length; c++) {
        sp = `p${offerVol[c].settlementPeriod}`;
        bidOfferVol.offer[sp] =
          bidOfferVol.offer[sp] + offerVol[c].totalVolumeAccepted;
      }
      const offerCashResponse = await fetch(
        `https://data.elexon.co.uk/bmrs/api/v1/balancing/settlement/indicative/cashflows/all/offer/${startDate}?${offerCallString}format=json`
      );
      const unfilterOfferCash = await offerCashResponse.json();
      const offerCash = unfilterOfferCash.data;

      for (let c = 0; c < offerCash.length; c++) {
        sp = `p${offerCash[c].settlementPeriod}`;
        bidOfferCash.offer[sp] =
          bidOfferCash.offer[sp] + offerCash[c].totalCashflow;
      }
      // }
      const response2 = await fetch(
        `https://data.elexon.co.uk/bmrs/api/v1/demand/outturn?settlementDateFrom=${startDate}&settlementDateTo=${startDate}&settlementPeriod=1&settlementPeriod=2&settlementPeriod=3&settlementPeriod=4&settlementPeriod=5&settlementPeriod=6&settlementPeriod=7&settlementPeriod=8&settlementPeriod=9&settlementPeriod=10&settlementPeriod=11&settlementPeriod=12&settlementPeriod=13&settlementPeriod=14&settlementPeriod=15&settlementPeriod=16&settlementPeriod=17&settlementPeriod=18&settlementPeriod=19&settlementPeriod=20&settlementPeriod=21&settlementPeriod=22&settlementPeriod=23&settlementPeriod=24&settlementPeriod=25&settlementPeriod=26&settlementPeriod=27&settlementPeriod=28&settlementPeriod=29&settlementPeriod=30&settlementPeriod=31&settlementPeriod=32&settlementPeriod=33&settlementPeriod=34&settlementPeriod=35&settlementPeriod=36&settlementPeriod=37&settlementPeriod=38&settlementPeriod=39&settlementPeriod=40&settlementPeriod=41&settlementPeriod=42&settlementPeriod=43&settlementPeriod=44&settlementPeriod=45&settlementPeriod=46&settlementPeriod=47&settlementPeriod=48&settlementPeriod=49&settlementPeriod=50&boundary=N&format=json`
      );
      let outturnData = await response2.json();
      outturn = outturnData;
      //console.log(outturn);

      let data = [
        [
          { type: "string", label: "Period" },
          { type: "number", label: "Bid Vol" },
          { type: "number", label: "Offer Vol" },
          { type: "number", label: "Bid Cash" },
          { type: "number", label: "Offer Cash" },
          { type: "number", label: "Disbsad Cash" },
          { type: "number", label: "Total Cash" },
        ],
      ];
      let temp = [];
      let disbsadTotal;
      for (let p = 1; p <= 50; p++) {
        const responseDisbsad = await fetch(
          `https://data.elexon.co.uk/bmrs/api/v1/balancing/nonbm/disbsad/details?settlementDate=${startDate}&settlementPeriod=${p}&format=json`
        );
        let unfilteredDisbsad = await responseDisbsad.json();
        let disbsad = unfilteredDisbsad.data;

        const group = (fn) => (xs) =>
          Object.values(
            xs.reduce(
              (a, x, _, __, k = fn(x)) => ((a[k] = [...(a[k] || []), x]), a),
              {}
            )
          );

        const sum = (ns) => ns.reduce((a, b) => a + b, 0);

        const combine = (xs) =>
          group((x) => x.startTime)(xs).map((xs) => ({
            settlementPeriod: xs[0].settlementPeriod,
            cost: sum(xs.map((x) => x.cost)),
          }));
        disbsadTotal = combine(disbsad);
        console.log(disbsadTotal);

        if (disbsadTotal.length > 0) {
          //console.log(disbsadTotal[0].cost);
          disbsadCash[`p${p}`] = disbsadTotal[0].cost;
        }

        //console.log(disbsadCash[`p${p}`]);
        temp = [];
        temp.push(p);
        if (bidOfferVol.bid[`p${p}`] !== 0) {
          temp.push(bidOfferVol.bid[`p${p}`]);
        } else temp.push(null);

        if (bidOfferVol.offer[`p${p}`] !== 0) {
          temp.push(bidOfferVol.offer[`p${p}`]);
        } else temp.push(null);

        if (bidOfferCash.bid[`p${p}`] !== 0) {
          temp.push(bidOfferCash.bid[`p${p}`]);
        } else temp.push(null);

        if (bidOfferCash.offer[`p${p}`] !== 0) {
          temp.push(bidOfferCash.offer[`p${p}`]);
        } else temp.push(null);

        if (disbsadCash[`p${p}`] !== 0) {
          temp.push(disbsadCash[`p${p}`]);
        } else temp.push(null);

        temp.push(
          bidOfferCash.bid[`p${p}`] +
            bidOfferCash.offer[`p${p}`] +
            disbsadCash[`p${p}`]
        );

        data.push(temp);
      }
      //console.log(data);
      setChartData(data);

      let data3 = [
        [
          { type: "string", label: "Period" },
          { type: "number", label: "Cost per MWh of Demand Outturn" },
          { type: "number", label: "Demand Outturn" },
        ],
      ];

      let p;
      if (outturn) {
        for (let i = 1; i <= 50; i++) {
          temp = [];
          temp.push(i);
          p = outturn.data[i - 1]?.settlementPeriod;
          temp.push(
            (bidOfferCash.bid[`p${p}`] +
              bidOfferCash.offer[`p${p}`] +
              disbsadCash[`p${p}`]) /
              outturn.data[p - 1]?.initialDemandOutturn
          );
          temp.push(outturn.data[p - 1]?.initialDemandOutturn);
          data3.push(temp);
        }
      }
      setChartData2(data3);
      setLoading(false);
    };

    getData();
  }, [datePicker]);

  const options = {
    title: "Bid Offer Data",
    width: 1800,
    height: 400,

    series: {
      0: { lineWidth: 2, pointSize: 2.1 },
      1: { lineWidth: 2, pointSize: 2.1 },

      2: { targetAxisIndex: 1, type: "bars" },
      3: { targetAxisIndex: 1, type: "bars" },
      4: { targetAxisIndex: 1, type: "bars" },
      5: { targetAxisIndex: 1, type: "bars" },
    },
    hAxis: {
      textStyle: { fontSize: 10 },
      title: "Period",

      minValue: 0,
      maxValue: 50,
      viewWindow: {
        max: 50,
      },
    },
    vAxes: {
      0: { title: "Bid Offer MWh" },
      1: { title: "Bid Ofer Cash" },
    },
    chartArea: { backgroundColor: "#fffff0" },
  };
  const options2 = {
    title:
      "Cost of Balancing and Constraints per MWh of Initial Demand Outturn",
    width: 1800,
    height: 400,

    series: {
      1: { lineWidth: 2, pointSize: 2.1 },
      0: { lineWidth: 2, pointSize: 2.1, targetAxisIndex: 1 },
    },
    hAxis: {
      textStyle: { fontSize: 10 },
      title: "Period",

      minValue: 0,
      maxValue: 50,
      viewWindow: {
        max: 50,
      },
    },
    vAxes: {
      0: { title: "MW" },
      1: { title: "£/MWh" },
    },
    chartArea: { backgroundColor: "#fffff0" },
  };
  return (
    <>
      {loading ? (
        <div style={{ textAlign: "center" }}>
          <ClipLoader loading={loading} />{" "}
        </div>
      ) : (
        <>
          <div
            style={{
              border: "1px solid #DCDCDC",
              height: "445px",
              width: "1825px",
              float: "none",
            }}
          >
            <Chart chartType="LineChart" options={options} data={chartData} />
          </div>
          <div
            style={{
              border: "1px solid #DCDCDC",
              height: "445px",
              width: "1825px",
              float: "none",
            }}
          >
            <Chart chartType="LineChart" options={options2} data={chartData2} />
          </div>
        </>
      )}
    </>
  );
};

export default TotalBidOffer4;
