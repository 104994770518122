import React from "react";
import { useState, useEffect, useContext } from "react";
import { Chart } from "react-google-charts";
import { useParams } from "react-router-dom";
import MainContext from "../context/MainContext";
import { addDays, format, getDate, getMonth } from "date-fns";
import { ClipLoader } from "react-spinners";
import { getTimezoneOffset } from "date-fns-tz";
import { findAllByTestId } from "@testing-library/react";

const WindForecast = () => {
  const { datePicker, loading, setLoading } = useContext(MainContext);

  const [chartData, setChartData] = useState([]);
  const [options, setOptions] = useState([]);

  let series1;
  let series2;

  useEffect(() => {
    setLoading(true);
    const startDate = format(new Date(datePicker), "yyyy-MM-dd");
    const startDate1 = format(new Date(addDays(startDate, 1)), "yyyy-MM-dd");
    const startDate2 = format(new Date(addDays(startDate, 2)), "yyyy-MM-dd");
    const endDate = format(new Date(addDays(startDate, 2)), "yyyy-MM-dd");
    let tzDiff = getTimezoneOffset("Europe/Dublin", startDate) / 1000 / 60 / 60;
    let newStartDate;
    let newEndDate;
    if (tzDiff === 1) {
      newStartDate =
        format(new Date(addDays(startDate, -1)), "yyyy-MM-dd") + "T23%3A00z";
      newEndDate =
        format(new Date(addDays(startDate, +2)), "yyyy-MM-dd") + "T22%3A30z";
    } else {
      newStartDate = startDate + "T00%3A00z";
      newEndDate =
        format(new Date(addDays(startDate, +2)), "yyyy-MM-dd") + "T23%3A30z";
    }
    console.log(newStartDate, newEndDate);
    const getData = async () => {
      const response = await fetch(
        `https://data.elexon.co.uk/bmrs/api/v1/forecast/generation/wind/earliest?from=${newStartDate}&to=${newEndDate}&format=json`
      );
      let earliestWind = await response.json();
      series1 = earliestWind.data[0].publishTime;

      const response2 = await fetch(
        `https://data.elexon.co.uk/bmrs/api/v1/forecast/generation/wind/latest?from=${newStartDate}&to=${newEndDate}&format=json`
      );
      let latestWind = await response2.json();
      series2 = latestWind.data[0].publishTime;

      const response3 = await fetch(
        `https://data.elexon.co.uk/bmrs/api/v1/datasets/FUELHH?settlementDateFrom=${startDate}&settlementDateTo=${endDate}&fuelType=WIND&format=json`
      );
      let UnfilteredWindOutturn = await response3.json();
      //console.log(UnfilteredWindOutturn);
      let windOutturn = UnfilteredWindOutturn.data.sort(
        (a, b) => new Date(a.startTime) - new Date(b.startTime)
      );
      //console.log(windOutturn);

      const bmuResponse = await fetch(
        "https://data.elexon.co.uk/bmrs/api/v1/reference/bmunits/all"
      );
      const bmus = await bmuResponse.json();
      const windBmus = bmus.filter((x) => x.fuelType === "WIND");

      let bmuString = "";
      for (let b = 0; b < windBmus.length; b++) {
        bmuString = bmuString + "bmUnit=" + windBmus[b].elexonBmUnit + "&";
      }
      // console.log(bmuString);
      let bid;
      let bid1;
      let bid2;
      if (bmuString.length > 0) {
        const responseTotalBmuBids = await fetch(
          `https://data.elexon.co.uk/bmrs/api/v1/balancing/settlement/acceptance/volumes/all/bid/${startDate}?${bmuString}format=json`
        );
        const unfilterBidVol = await responseTotalBmuBids.json();
        const bidVol = unfilterBidVol.data;

        const responseTotalBmuBids1 = await fetch(
          `https://data.elexon.co.uk/bmrs/api/v1/balancing/settlement/acceptance/volumes/all/bid/${startDate1}?${bmuString}format=json`
        );
        const unfilterBidVol1 = await responseTotalBmuBids1.json();
        const bidVol1 = unfilterBidVol1.data;

        const responseTotalBmuBids2 = await fetch(
          `https://data.elexon.co.uk/bmrs/api/v1/balancing/settlement/acceptance/volumes/all/bid/${startDate2}?${bmuString}format=json`
        );
        const unfilterBidVol2 = await responseTotalBmuBids2.json();
        const bidVol2 = unfilterBidVol2.data;
        //console.log(bidVol);

        const group = (fn) => (xs) =>
          Object.values(
            xs.reduce(
              (a, x, _, __, k = fn(x)) => ((a[k] = [...(a[k] || []), x]), a),
              {}
            )
          );

        const sum = (ns) => ns.reduce((a, b) => a + b, 0);

        const combine = (xs) =>
          group((x) => x.startTime)(xs).map((xs) => ({
            startTime: xs[0].startTime,
            totalVolumeAccepted: sum(xs.map((x) => x.totalVolumeAccepted)),
          }));

        bid = combine(bidVol);
        bid.sort((a, b) => new Date(a.startTime) - new Date(b.startTime));
        bid1 = combine(bidVol1);
        bid1.sort((a, b) => new Date(a.startTime) - new Date(b.startTime));
        bid2 = combine(bidVol2);
        bid2.sort((a, b) => new Date(a.startTime) - new Date(b.startTime));
        //console.log(bid);
      }
      let data;
      let hasOutturn = false;

      let hasBids = false;
      if (
        (bid.length > 0 || bid1.length > 0 || bid2.length > 0) &&
        windOutturn.length > 0
      ) {
        hasBids = true;
        hasOutturn = true;

        data = [
          [
            "Time",
            "Latest Forecast",
            "Earliest Forecast",
            "Wind Outturn",
            "Bids Turning Wind Down",
          ],
        ];
      } else if (
        bid.length === 0 &&
        bid1.length === 0 &&
        bid2.length === 0 &&
        windOutturn.length > 0
      ) {
        hasBids = false;
        hasOutturn = true;
        data = [
          ["Time", "Latest Forecast", "Earliest Forecast", "Wind Outturn"],
        ];
      } else {
        hasBids = false;
        hasOutturn = false;
        data = [["Time", "Latest Forecast", "Earliest Forecast"]];
      }

      let temp = [];

      if (latestWind) {
        for (let i = 0; i < latestWind.data.length; i++) {
          if (latestWind.data[i]) {
            temp = [];
            temp.push(new Date(latestWind.data[i].startTime));
            temp.push(latestWind.data[i].generation);
            temp.push(null);
            if (hasOutturn) {
              temp.push(null);
            }
            if (hasBids) {
              temp.push(null);
            }
            data.push(temp);
          }
        }
        for (let i = 0; i < earliestWind.data.length; i++) {
          if (earliestWind.data[i]) {
            temp = [];
            temp.push(new Date(earliestWind.data[i].startTime));
            temp.push(null);
            temp.push(earliestWind.data[i].generation);
            if (hasOutturn) {
              temp.push(null);
            }
            if (hasBids) {
              temp.push(null);
            }
            data.push(temp);
          }
        }
        for (let i = 0; i < windOutturn.length; i++) {
          if (windOutturn[i]) {
            temp = [];
            temp.push(new Date(windOutturn[i].startTime));
            temp.push(null);
            temp.push(null);
            temp.push(windOutturn[i].generation);
            if (hasBids) {
              temp.push(null);
            }
            data.push(temp);
          }
        }

        for (let i = 0; i < bid.length; i++) {
          temp = [];
          temp.push(new Date(bid[i].startTime));
          temp.push(null);
          temp.push(null);
          temp.push(null);
          temp.push(bid[i].totalVolumeAccepted * 2);
          data.push(temp);
        }
        for (let i = 0; i < bid1.length; i++) {
          temp = [];
          temp.push(new Date(bid1[i].startTime));
          temp.push(null);
          temp.push(null);
          temp.push(null);
          temp.push(bid1[i].totalVolumeAccepted * 2);
          data.push(temp);
        }
        for (let i = 0; i < bid1.length; i++) {
          temp = [];
          temp.push(new Date(bid1[i].startTime));
          temp.push(null);
          temp.push(null);
          temp.push(null);
          temp.push(bid1[i].totalVolumeAccepted * 2);
          data.push(temp);
        }
        for (let i = 0; i < bid2.length; i++) {
          temp = [];
          temp.push(new Date(bid2[i].startTime));
          temp.push(null);
          temp.push(null);
          temp.push(null);
          temp.push(bid2[i].totalVolumeAccepted * 2);
          data.push(temp);
        }

        //console.log(data);
        setChartData(data);
      }
      setLoading(false);
    };

    setOptions({
      title: "Wind Forecast",
      height: 800,
      width: 1748,

      series: {
        0: { lineWidth: 2, pointSize: 2.1 },
        1: { lineWidth: 2, pointSize: 2.1 },
        2: { lineWidth: 2, pointSize: 2.1 },
        3: { lineWidth: 0, pointSize: 5 },
      },
      hAxis: {
        textStyle: { fontSize: 14 },
        format: "dd-MM-yyyy HH:mm",
        title: "Period",

        //format: 'HH:mm',
        //minValue: new Date(y,m,d,0,0,0),
        // maxValue: new Date(y,m,d+1,0,0,0),
        //minValue: 0,
        //maxValue: 50,
        viewWindow: {
          //min: new Date(y,m,d,0,0,0),
          //max: new Date(y,m,d+1,0,0,0)
          //min: 1,
          // max: 50,
        },
      },
      vAxes: {
        0: { title: "MW" },
      },
      chartArea: { backgroundColor: "#fffff0" },
    });
    getData();
  }, [datePicker]);

  return (
    <>
      {loading ? (
        <div style={{ textAlign: "center" }}>
          <ClipLoader loading={loading} />{" "}
        </div>
      ) : (
        <div
          style={{
            border: "1px solid #DCDCDC",
            height: "810px",
            width: "1750px",
            float: "none",
          }}
        >
          <Chart chartType="ScatterChart" options={options} data={chartData} />
        </div>
      )}
    </>
  );
};

export default WindForecast;
