import React from "react";
import { useState, useEffect, useContext } from "react";
import { Chart } from "react-google-charts";
import { useParams } from "react-router-dom";
import MainContext from "../context/MainContext";
import { addDays, format } from "date-fns";
import { ClipLoader } from "react-spinners";
import axios from "axios";
import { getTimezoneOffset } from "date-fns-tz";

const GenByFuel7 = () => {
  const [fuelList, setFuelList] = useState([]);
  const [fuelData, setFuelData] = useState([]);
  const [content, setContent] = useState([]);
  const [bmuList, setBmuList] = useState([]);

  const { datePicker, loading, setLoading } = useContext(MainContext);

  useEffect(() => {
    const GetData = async () => {
      setLoading(true);
      const response = await fetch(
        "https://data.elexon.co.uk/bmrs/api/v1/reference/bmunits/all"
      );
      const bmus = await response.json();
      setBmuList(bmus);
    };
    GetData();
  }, [datePicker]);

  useEffect(() => {
    setLoading(true);
    //console.log("test2");
    //const startDate = format(new Date(datePicker), "yyyy-MM-dd");
    //const endDate = format(new Date(addDays(datePicker, 1)), "yyyy-MM-dd");
    //const yr = new Date(startDate).getFullYear();
    //const month = new Date(startDate).getMonth();
    //const day = new Date(startDate).getDate();

    const localStartDate = format(new Date(datePicker), "yyyy-MM-dd");
    let startDateTzDiff =
      getTimezoneOffset("Europe/Dublin", localStartDate) / 1000 / 60 / 60;
    const localEndDate = format(new Date(addDays(datePicker, 1)), "yyyy-MM-dd");
    let endDateTzDiff =
      getTimezoneOffset("Europe/Dublin", localEndDate) / 1000 / 60 / 60;

    let startDate;
    let endDate;
    if (startDateTzDiff === 1) {
      startDate =
        format(new Date(addDays(localStartDate, -1)), "yyyy-MM-dd") +
        "T23%3A30z";
    } else {
      startDate = localStartDate + "T00%3A30z";
    }
    if (endDateTzDiff === 1) {
      endDate =
        format(new Date(addDays(localEndDate, -1)), "yyyy-MM-dd") + "T23%3A00z";
    } else {
      endDate = localEndDate + "T00%3A00z";
    }

    const getData = async () => {
      //console.log(startDate, endDate);
      const response = await fetch(
        `https://data.elexon.co.uk/bmrs/api/v1/datasets/FUELHH?publishDateTimeFrom=${startDate}&publishDateTimeTo=${endDate}&format=json`
        //`https://data.elexon.co.uk/bmrs/api/v1/datasets/FUELHH?publishDateTimeFrom=${startDate}T00%3A00&publishDateTimeTo=${endDate}T00%3A00&format=json`
        //`http://localhost:3500/fuel/gen/y/${yr}/m/${month}/d/${day}`
      );
      const headers = { "Content-Type": "application/json" };

      //console.log(response);
      let data = await response.json();
      //console.log(data.data);
      setFuelData(data);

      let fuelType = [
        "CCGT",
        "BIOMASS",
        "NUCLEAR",
        "WIND",
        "PS",
        "NPSHYD",
        "OCGT",
        "OTHER",
        "OIL",
        "COAL",
        "INTFR",
        "INTIFA2",
        "INTELEC",
        "INTIRL",
        "INTEW",
        "INTNED",
        "INTNEM",
        "INTNSL",
        "INTVKL",
      ];

      for (let i = 0; i < data.data.length; i++) {
        //console.log(data.data.length);
        for (let a = 0; a < data.data.length; a++) {
          let fuel = data.data[a].fuelType;
          //console.log(fuel);
          if (!fuelType.includes(fuel)) {
            fuelType.push(fuel);
          }
        }
        setFuelList(fuelType);
      }
      //console.log(fuelType);
    };
    getData();
  }, [bmuList]);

  useEffect(() => {
    setLoading(true);
    //console.log("test3");
    setLoading(true);
    const startDate = format(new Date(datePicker), "yyyy-MM-dd");
    const endDate = format(new Date(addDays(datePicker, 1)), "yyyy-MM-dd");
    const y = new Date(startDate).getFullYear();
    const m = new Date(startDate).getMonth();
    const d = new Date(startDate).getDate();

    const getData = async (fuel) => {
      let bidOfferVol = {
        bid: {
          p1: 0,
          p2: 0,
          p3: 0,
          p4: 0,
          p5: 0,
          p6: 0,
          p7: 0,
          p8: 0,
          p9: 0,
          p10: 0,
          p11: 0,
          p12: 0,
          p13: 0,
          p14: 0,
          p15: 0,
          p16: 0,
          p17: 0,
          p18: 0,
          p19: 0,
          p20: 0,
          p21: 0,
          p22: 0,
          p23: 0,
          p24: 0,
          p25: 0,
          p26: 0,
          p27: 0,
          p28: 0,
          p29: 0,
          p30: 0,
          p31: 0,
          p32: 0,
          p33: 0,
          p34: 0,
          p35: 0,
          p36: 0,
          p37: 0,
          p38: 0,
          p39: 0,
          p40: 0,
          p41: 0,
          p42: 0,
          p43: 0,
          p44: 0,
          p45: 0,
          p46: 0,
          p47: 0,
          p48: 0,
          p49: 0,
          p50: 0,
        },
        offer: {
          p1: 0,
          p2: 0,
          p3: 0,
          p4: 0,
          p5: 0,
          p6: 0,
          p7: 0,
          p8: 0,
          p9: 0,
          p10: 0,
          p11: 0,
          p12: 0,
          p13: 0,
          p14: 0,
          p15: 0,
          p16: 0,
          p17: 0,
          p18: 0,
          p19: 0,
          p20: 0,
          p21: 0,
          p22: 0,
          p23: 0,
          p24: 0,
          p25: 0,
          p26: 0,
          p27: 0,
          p28: 0,
          p29: 0,
          p30: 0,
          p31: 0,
          p32: 0,
          p33: 0,
          p34: 0,
          p35: 0,
          p36: 0,
          p37: 0,
          p38: 0,
          p39: 0,
          p40: 0,
          p41: 0,
          p42: 0,
          p43: 0,
          p44: 0,
          p45: 0,
          p46: 0,
          p47: 0,
          p48: 0,
          p49: 0,
          p50: 0,
        },
      };

      const filteredFuelData = fuelData.data.filter((x) => x.fuelType === fuel);
      filteredFuelData.sort(
        (a, b) =>
          new Date(a.startTime).getTime() - new Date(b.startTime).getTime()
      );
      //console.log(fuel);
      //console.log(y);
      //console.log(m);
      //console.log(d);

      const BOAFuels = [
        "CCGT",
        "BIOMASS",
        "NUCLEAR",
        "WIND",
        "PS",
        "NPSHYD",
        "OCGT",
        "OTHER",
        "OIL",
        "COAL",
      ];
      //let bidOffer = null;
      // console.log(bidOffer.length);
      //console.log(BOAFuels.indexOf(fuel));

      let chartData = [];

      if (BOAFuels.indexOf(fuel) !== -1) {
        const bmuFuelList = bmuList.filter((x) => x.fuelType === fuel);
        //console.log(bmuFuelList);
        let bmuString = "";

        for (let b = 0; b < bmuFuelList.length; b++) {
          let bmuname = bmuFuelList[b].elexonBmUnit;

          bmuString = bmuString + "bmUnit=" + bmuFuelList[b].elexonBmUnit + "&";
        }

        //console.log(bmuString);
        if (bmuString.length > 0) {
          const responseTotalBmuBids = await fetch(
            `https://data.elexon.co.uk/bmrs/api/v1/balancing/settlement/acceptance/volumes/all/bid/${startDate}?${bmuString}format=json`
          );
          const unfilterBidVol = await responseTotalBmuBids.json();
          const bidVol = unfilterBidVol.data;
          //console.log(bidVol);
          let sp;
          for (let c = 0; c < bidVol.length; c++) {
            sp = `p${bidVol[c].settlementPeriod}`;
            bidOfferVol.bid[sp] =
              bidOfferVol.bid[sp] + bidVol[c].totalVolumeAccepted;
          }
          const responseTotalBmuOffers = await fetch(
            `https://data.elexon.co.uk/bmrs/api/v1/balancing/settlement/acceptance/volumes/all/offer/${startDate}?${bmuString}format=json`
          );
          const unfilterOfferVol = await responseTotalBmuOffers.json();
          const offerVol = unfilterOfferVol.data;
          //console.log(offerVol);
          //let sp;
          for (let c = 0; c < offerVol.length; c++) {
            sp = `p${offerVol[c].settlementPeriod}`;
            bidOfferVol.offer[sp] =
              bidOfferVol.offer[sp] + offerVol[c].totalVolumeAccepted;
          }
        }

        chartData = [
          [
            { type: "string", label: "Period" },
            { type: "number", label: "MW" },
            { type: "number", label: "Offer" },
            { type: "number", label: "bid" },
          ],
        ];
        //console.log(bidOffer.length);
        for (let a = 0; a < filteredFuelData.length; a++) {
          let p = filteredFuelData[a].settlementPeriod;
          let temp = [];
          temp.push(filteredFuelData[a].settlementPeriod);
          temp.push(filteredFuelData[a].generation);

          //if (bidOffer != null) {
          if (bidOfferVol.offer[`p${p}`] !== 0) {
            temp.push(bidOfferVol.offer[`p${p}`] * 2);
          } else {
            temp.push(null);
          }
          if (bidOfferVol.bid[`p${p}`] !== 0) {
            temp.push(bidOfferVol.bid[`p${p}`] * 2);
          } else {
            temp.push(null);
          }

          chartData.push(temp);
        }
      } else {
        chartData = [
          [
            { type: "string", label: "Period" },
            { type: "number", label: "MW" },
          ],
        ];
        //console.log(bidOffer.length);
        for (let a = 0; a < filteredFuelData.length; a++) {
          let p = filteredFuelData[a].settlementPeriod;
          let temp = [];
          temp.push(filteredFuelData[a].settlementPeriod);
          temp.push(filteredFuelData[a].generation);

          //if (bidOffer != null) {

          chartData.push(temp);
        }
      }
      var options = {
        title: fuel,
        width: 495,
        height: 300,
        series: {
          0: { lineWidth: 1, pointSize: 1.1 },
          1: { lineWidth: 1, pointSize: 1.1, type: "lines" },
          2: { lineWidth: 1, pointSize: 1.1, type: "lines" },
        },
        hAxis: {
          textStyle: { fontSize: 10 },

          title: "Period",
          //format: 'HH:mm',
          //minValue: new Date(y,m,d,0,0,0),
          // maxValue: new Date(y,m,d+1,0,0,0),
          minValue: 0,
          maxValue: 50,
          viewWindow: {
            //min: new Date(y,m,d,0,0,0),
            //max: new Date(y,m,d+1,0,0,0)
            //min: 1,
            max: 50,
          },
        },
        vAxis: {
          minValue: 0,
          textStyle: { fontSize: 10 },
        },
        chartArea: { backgroundColor: "#fffff0" },
      };

      return (
        <div
          key={fuel}
          style={{
            height: "330px",
            width: "500px",
            border: "1px solid #DCDCDC",
            float: "left",
          }}
        >
          <Chart chartType="LineChart" options={options} data={chartData} />
        </div>
      );
    };

    let promises = [];
    // console.log(bmuList);
    for (let i = 0; i < fuelList.length; i++) {
      //bmuFuelList.forEach((x)=>)

      //getData(fuelList[i]);
      promises.push(getData(fuelList[i]));
    }

    //console.log(promises);
    Promise.all(promises).then((arrOfResults) => {
      setContent([]);
      for (let i = 0; i < arrOfResults.length; i++) {
        setContent((current) => [...current, arrOfResults[i]]);
      }
      setLoading(false);
    });
  }, [fuelList]);

  return (
    <>
      {loading ? (
        <div style={{ textAlign: "center" }}>
          <ClipLoader loading={loading} />{" "}
        </div>
      ) : (
        <div>{content}</div>
      )}
    </>
  );
};

export default GenByFuel7;
