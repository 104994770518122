import React from "react";
import { useState, useEffect, useContext } from "react";
import { Chart } from "react-google-charts";
import { useParams } from "react-router-dom";
import MainContext from "../context/MainContext";
import { addDays, format, getDate, getMonth } from "date-fns";
import { ClipLoader } from "react-spinners";
import { getTimezoneOffset } from "date-fns-tz";

const Oc2Margin2_14 = () => {
  const { datePicker, loading, setLoading } = useContext(MainContext);
  const [data, setData] = useState([]);

  useEffect(() => {
    const getData = async () => {
      setLoading(true);

      const response = await fetch(
        `https://data.elexon.co.uk/bmrs/api/v1/datasets/OCNMFD2?format=json`
      );
      const oc2 = await response.json();

      let chartData = [
        [
          { type: "string", label: "Date" },
          { type: "number", label: "Margin" },
        ],
      ];
      let temp = [];
      for (let a = 0; a < oc2.data.length; a++) {
        temp = [];
        temp.push(oc2.data[a].forecastDate);
        temp.push(oc2.data[a].margin);
        chartData.push(temp);
      }
      setData(chartData);
      setLoading(false);
    };

    getData();
  }, []);

  const options = {
    title: "OCNMF2 - 2 to 14 Days Ahead Demand Margin Forcast ",

    height: 800,
    width: 1748,
    series: {
      0: {
        type: "bars",
      },
    },
    hAxis: {
      textStyle: { fontSize: 10 },
      title: "Date",
    },
    vAxes: {
      0: { title: "Margin MW" },
    },
    chartArea: { backgroundColor: "#fffff0" },
  };

  return (
    <>
      {loading ? (
        <div style={{ textAlign: "center" }}>
          <ClipLoader loading={loading} />{" "}
        </div>
      ) : (
        <div
          style={{
            border: "1px solid #DCDCDC",
            height: "810px",
            width: "1750px",
            float: "none",
          }}
        >
          <Chart chartType="LineChart" options={options} data={data} />
        </div>
      )}
    </>
  );
};

export default Oc2Margin2_14;
